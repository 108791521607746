import { useEffect, useState } from 'react';
import { Button, Form, Row, Col, Popconfirm } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import FloatLabel from '../../Common/FloatLabel';
import LoaderSpinner from '../../Common/LoaderSpinner';
import './index.scss';
import { referralServices } from '../../../Services/referralService';
import { errorToast } from "../../Common/ToastService";

// @ts-ignore
const ManageReferralBonus = (props: any) => {
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [lob, setLob] = useState<string>('tech');
  const [referralBonuses, setReferralBonuses] = useState([]);
  const [fieldsArr, setFieldsArr] = useState([1]);
  const [fieldsArrSelected, setFieldsArrSelected] = useState<number[]>([]);
  const [isReload, setIsReload] = useState(false);
  const [showSaved, setShowSaved] = useState(false);
  const [showUpdated, setShowUpdated] = useState(false);
  const [isUpdatedId, setIsUpdatedId] = useState(0);
  const [updatedExp, setUpdatedExp] = useState('');
  const [updatedAmt, setUpdatedAmt] = useState<any>(0);
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);

  useEffect(() => {
    let _formsData = form.getFieldsValue();
    _formsData?.exp_amt?.map((item: object, i: number) => {
      if((i == isUpdatedId) && _formsData?.exp_amt[i]){
        if(updatedExp){
          _formsData.exp_amt[i].exp = updatedExp;
        }
        if(updatedAmt){
          _formsData.exp_amt[i].amt = updatedAmt;
        }
      }
    });
    form.setFieldsValue(_formsData);
  }, [updatedExp, updatedAmt]);

  useEffect(() => {
    (async () => {
      const data = await getReferralBonuses();
      setReferralBonuses(data);
    })();
  }, [lob, isReload]);

  const getReferralBonuses = async () => {
    setLoader(true);
    const getReferralBonusResponse = await referralServices.getReferralBonus(lob);
    setLoader(false);
    return getReferralBonusResponse.data;
  };

  const onFormSubmit = async (values: any) => {
    const bonusData = {
      lob: values.lob,
      bonus: values.experience_amount,
    };
    try {
      const response = await referralServices.createReferralBonus({ bonusData });
      if (response) {
        setShowSaved(true);
        setTimeout(() => {
          setShowSaved(false);
        }, 2000);
        form.resetFields();
        setIsReload(!isReload);
        setFieldsArrSelected([]);
      }
    } catch (error: any) {
      errorToast(error.response.data.error);
    }
  };

  const handleAddMore = () => {
    let fieldsArrClone;
    if (referralBonuses.length !== 0) {
      fieldsArrClone = [...fieldsArrSelected];
      console.log('🚀 ~ file: index.tsx:50 ~ handleAddMore ~ fieldsArrClone:', fieldsArrClone);
      let totalCount = fieldsArrClone.length;
      fieldsArrClone.push(totalCount + 1);
      setFieldsArrSelected(fieldsArrClone);
    } else {
      fieldsArrClone = [...fieldsArr];
      let totalCount = fieldsArrClone.length;
      fieldsArrClone.push(totalCount + 1);
      setFieldsArr(fieldsArrClone);
    }
  };

  const handleRemove = (idx: number) => {
    setLoader(true);
    let fieldsArrClone;
    if (referralBonuses.length !== 0) {
      fieldsArrClone = [...fieldsArrSelected];
      fieldsArrClone.splice(idx, 1);
      setFieldsArrSelected(fieldsArrClone);
      if (!!form.getFieldValue('experience_amount')) {
        let formData = [...form.getFieldValue('experience_amount')];
        formData.splice(idx, 1);
        form.setFieldsValue({ experience_amount: formData });
      }
    } else {
      fieldsArrClone = [...fieldsArr];
      fieldsArrClone.splice(idx, 1);
      setFieldsArr(fieldsArrClone);
      if (!!form.getFieldValue('experience_amount')) {
        let formData = [...form.getFieldValue('experience_amount')];
        formData.splice(idx, 1);
        form.setFieldsValue({ experience_amount: formData });
      }
    }
    setTimeout(() => {
      setLoader(false);
    }, 0);
  };

  const deleteReferralBonus = async (idx: number) => {
    setLoader(true);

    try {
      const response = await referralServices.deleteReferralBonus(idx);
      if (response) {
        setIsReload(!isReload);
      }
    } catch (error: any) {
      errorToast(error.response.data.error);
    }
    setTimeout(() => {
      setLoader(false);
    }, 0);
  };

  const updateReferralBonus = async (idx: number) => {
    if (isNaN(updatedAmt) || updatedAmt > 1000000) {
      return;
    }

    let _formsData = form.getFieldsValue();
    let _updatedExp = updatedExp;
    let _updatedAmt = updatedAmt;
    _formsData?.exp_amt?.map((item: object, i: number) => {
      if((i == idx) && _formsData.exp_amt[i]){
        if(_updatedExp === ""){
          _updatedExp = _formsData.exp_amt[i].exp;
        }
        if(_updatedAmt === ""){
          _updatedAmt = _formsData.exp_amt[i].amt;
        }
      }
    });

    let _isExp = (_updatedExp === "") || (_updatedExp === undefined);
    let _isAmt = (_updatedAmt === "") || (_updatedAmt === undefined);

    if(_isExp && _isAmt){
      return
    }
    if(_isExp){
      return;
    }
    if(_isAmt){
      return;
    }
    setLoader(true);
    try {
      const response = await referralServices.updateReferralBonus(idx, {
        experience: updatedExp,
        amount: updatedAmt,
      });
      if (response) {
        const updatedReferralBonuses: any = referralBonuses.map((fields: any) => {
          if (fields.id === idx) {
            return {
              ...fields,
              bonus: {
                ...fields.bonus,
                experience: updatedExp,
                amount: updatedAmt,
              },
            };
          }
          return fields;
        });
        setReferralBonuses(updatedReferralBonuses);
        setIsUpdatedId(0);
        setUpdatedExp('');
        setUpdatedAmt(0);
        setShowUpdated(true);
        setTimeout(() => {
          setShowUpdated(false);
        }, 2000);
      }
    } catch (error: any) {
      errorToast(error.response.data.error);
    }
    setLoader(false);
  };

  if (loader) {
    return <LoaderSpinner noText={true}/>;
  }

  const handleChange = (value: string) => {
    setLob(value);
    setFieldsArr([1]);
  };

  const validateUpdateBtn = (id: number, value: any) => {
    let expValue = value.exp === "" ? value.exp : form.getFieldValue('exp_amt')?.[id]?.exp;
    let amtFormat = /^(?:\d*)$/;
    let amtValue = value.amt === "" ? value.amt : form.getFieldValue('exp_amt')?.[id]?.amt;

    if (!expValue){
      setShowUpdateBtn(false);
      return false;
    }
    else if (!amtValue){
      setShowUpdateBtn(false);
      return false;
    }
    else if (amtValue >= 1000000) {
      setShowUpdateBtn(false);
      return false;
    }
    else if(!amtValue.match(amtFormat)){
      setShowUpdateBtn(false);
      return false;
    }
    else{
      setShowUpdateBtn(true);
    }
  };

  return (
    <div className="manage-referral-bonus">
      <Form form={form} onFinish={onFormSubmit}>
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="lob"
              rules={[
                {
                  required: true,
                  message: 'Please select lob',
                },
              ]}
              initialValue={lob}
            >
              <FloatLabel
                label="LOB"
                placeholder="Select Lob"
                name="lob"
                type="select"
                onChange={handleChange}
                optionsArray={[
                  {
                    label: 'Tech',
                    value: 'tech',
                  },
                  {
                    label: 'Non Tech',
                    value: 'non-tech',
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>

        <div>
          {referralBonuses.length > 0 &&
            referralBonuses.map((fields: any) => {
              return (
                <div className="experience-amount-wrapper" key={fields.id}>
                  <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                      <Form.Item
                        name={['exp_amt', fields.id, `exp`]}
                        className="experience-field"
                        initialValue={fields.bonus.experience}
                        getValueFromEvent={(e) => {
                          let _value = {exp: e.target.value, amt: undefined};
                          setIsUpdatedId(fields.id);
                          setUpdatedExp(e.target.value);
                          validateUpdateBtn(fields.id, _value);
                        }}
                        rules={[
                          {
                            validator: (_, value) => {
                              let expValue = form.getFieldValue('exp_amt')?.[fields.id]?.exp;
                              if (!expValue){
                                form.setFields([
                                  {
                                    name: ['exp_amt', fields.id, 'exp'],
                                    errors: ['Please enter Experience'],
                                  },
                                ]);
                                return Promise.reject('Please enter Experience');
                              }
                              else{
                                return Promise.resolve();
                              }
                            },
                          },
                        ]}
                      >
                        <FloatLabel 
                          label={<>Experience<span className='required-field'>*</span></>}
                          placeholder="Enter Experience"
                          name="exp" 
                          type="input" 
                          maxLength={100} 
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                      <Form.Item
                        name={['exp_amt', fields.id, `amt`]}
                        className="amount-field"
                        initialValue={fields.bonus.amount}
                        getValueFromEvent={(e) => {
                          let _value = {amt: e.target.value, exp: undefined};
                          setIsUpdatedId(fields.id);
                          setUpdatedAmt(e.target.value);
                          validateUpdateBtn(fields.id, _value);
                        }}
                        rules={[
                          {
                            validator: (_, value) => {
                              let amtFormat = /^(?:\d*)$/;
                              let amtValue = form.getFieldValue('exp_amt')?.[fields.id]?.amt;
                              if (!amtValue){
                                form.setFields([
                                  {
                                    name: ['exp_amt', fields.id, 'amt'],
                                    errors: ['Please enter amount'],
                                  },
                                ]);
                                return Promise.reject('Please enter amount');
                              }
                              else if (amtValue >= 1000000) {
                                form.setFields([
                                  {
                                    name: ['exp_amt', fields.id, 'amt'],
                                    errors: ['Please enter amount less than 10 lakhs'],
                                  },
                                ]);
                                return Promise.reject('Please enter amount less than 10 lakhs');
                              }
                              else if(!amtValue.match(amtFormat)){
                                form.setFields([
                                  {
                                    name: ['exp_amt', fields.id, 'amt'],
                                    errors: ['Please enter a valid amount'],
                                  },
                                ]);
                                return Promise.reject('Please enter a valid amount');
                              }
                              else{
                                return Promise.resolve();
                              }
                            },
                          },
                        ]}
                      >
                        <FloatLabel 
                          label={<>Amount<span className='required-field'>*</span></>}
                          placeholder="Enter Amount"
                          name="amt" 
                          type="input" 
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {(fields.id === isUpdatedId && showUpdateBtn) ? (
                    <Row>
                      <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                        <Button
                          style={{
                            marginTop: 10,
                            backgroundColor: '#DE1186',
                            color: 'white',
                            float: 'right',
                          }}
                          loading={false}
                          onClick={() => {
                            updateReferralBonus(fields.id);
                          }}
                        >
                          Update
                        </Button>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}

                  {fields.id !== 0 ? (
                    <Popconfirm
                      title="Delete Referral Bonus"
                      description="Are you sure to delete this referral bonus?"
                      onConfirm={() => deleteReferralBonus(fields.id)}
                      onCancel={() => console.log('cancel')}
                      okText="Yes"
                      cancelText="No"
                      disabled={false}
                    >
                      <CloseOutlined className="remove-btn" />
                    </Popconfirm>
                  ) : null}
                </div>
              );
            })}
        </div>

        <div>
          {referralBonuses.length === 0 ? (
            <>
              {fieldsArr.map((fields, index) => (
                <div className="experience-amount-wrapper" key={index}>
                  <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                      <Form.Item
                        name={['experience_amount', index, `experience`]}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter experience',
                          },
                        ]}
                        className="experience-field"
                      >
                        <FloatLabel
                          label={<>Experience<span className='required-field'>*</span></>}
                          placeholder="Enter Experience"
                          name="experience"
                          type="input"
                          maxLength={100}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                      <Form.Item
                        name={['experience_amount', index, `amount`]}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter amount',
                          },
                          {
                            pattern: /^(?:\d*)$/,
                            message: 'Please enter a valid amount',
                          },
                          {
                            validator: (_, value) => {
                              if (value < 1000000) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject('');
                              }
                            },
                            message: 'Please enter amount less than 10 lakhs',
                          },
                        ]}
                        validateFirst={true}
                        className="amount-field"
                      >
                        <FloatLabel
                          label={<>Amount<span className='required-field'>*</span></>}
                          placeholder="Enter Amount"
                          name="amount"
                          type="input"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  {index !== 0 ? (
                    <Popconfirm
                      title="Delete Referral Bonus"
                      description="Are you sure to delete this referral bonus?"
                      onConfirm={() => handleRemove(index)}
                      onCancel={() => console.log('cancel')}
                      okText="Yes"
                      cancelText="No"
                      disabled={false}
                    >
                      <CloseOutlined className="remove-btn" />
                    </Popconfirm>
                  ) : null}
                </div>
              ))}
            </>
          ) : (
            <>
              {fieldsArrSelected.map((fields, index) => (
                <div className="experience-amount-wrapper" key={index}>
                  <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                      <Form.Item
                        name={['experience_amount', index, `experience`]}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter experience',
                          },
                        ]}
                        className="experience-field"
                      >
                        <FloatLabel
                          label={<>Experience<span className='required-field'>*</span></>}
                          placeholder="Enter Experience"
                          name="experience"
                          type="input"
                          maxLength={100}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                      <Form.Item
                        name={['experience_amount', index, `amount`]}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter amount',
                          },
                          {
                            pattern: /^(?:\d*)$/,
                            message: 'Please enter a valid amount',
                          },
                          {
                            validator: (_, value) => {
                              if (value < 1000000) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject('');
                              }
                            },
                            message: 'Please enter amount less than 10 lakhs',
                          },
                        ]}
                        validateFirst={true}
                        className="amount-field"
                      >
                        <FloatLabel
                          label={<>Amount<span className='required-field'>*</span></>}
                          placeholder="Enter Amount"
                          name="amount"
                          type="input"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Popconfirm
                    title="Delete Referral Bonus"
                    description="Are you sure to delete this referral bonus?"
                    onConfirm={() => handleRemove(index)}
                    onCancel={() => console.log('cancel')}
                    okText="Yes"
                    cancelText="No"
                    disabled={false}
                  >
                    <CloseOutlined className="remove-btn" />
                  </Popconfirm>
                </div>
              ))}
            </>
          )}
        </div>

        <Form.Item wrapperCol={{ span: 24 }} className="footer-btns">
          <Button className="add-more-btn" onClick={() => handleAddMore()}>
            Add More
          </Button>
          {(fieldsArr.length > 0 && referralBonuses.length === 0) ||
          fieldsArrSelected.length > 0 ? (
            <Button className="save-btn" htmlType="submit" loading={false}>
              Save
            </Button>
          ) : (
            <></>
          )}
        </Form.Item>

        {showSaved && (
          <p style={{ color: '#DE1186', float: 'right' }}>
            <strong>Referral Bonus Saved!</strong>
          </p>
        )}
        {showUpdated && (
          <p style={{ color: '#DE1186', float: 'right' }}>
            <strong>Referral Bonus Updated!</strong>
          </p>
        )}
      </Form>
    </div>
  );
};

export default ManageReferralBonus;
