import { Navigate } from 'react-router-dom';
interface IProps {
  roles: string[];
  children: any;
}

const ProtectedRoute = (props: IProps) => {
  const userDataStr = localStorage.getItem('referral-user-data')!;
  const userData = JSON.parse(userDataStr);
  const { children, roles } = props;

  if (roles && Array.isArray(roles) && roles.includes(userData?.role)) {
    return children ? children : null;
  } else {
    if (userData?.role === 'ADMIN' || userData?.role === 'SUPERADMIN') {
      return <Navigate to="/admin/home" replace={true} />;
    } else {
      return <Navigate to="/" replace={true} />;
    }
  }
};

export default ProtectedRoute;
