import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import ManageFaq from './Components/Admin/ManageFaq';
import ManageFaqCategories from './Components/Admin/ManageFaqCategories';
import ManageReferralBonus from './Components/Admin/ManageReferralBonus';
import ManageUsers from './Components/SuperAdmin/ManageUsers';
import LoaderSpinner from './Components/Common/LoaderSpinner';
import ProtectedRoute from './protected';
const Faqs = React.lazy(() => import('./Components/Faqs'));
const Listing = React.lazy(() => import('./Components/JobListing/index'));
const MyReferral = React.lazy(() => import('./Components/MyReferral/index'));
const JobDescription = React.lazy(
  () => import('./Components/JobDescription/index')
);
const AdminHomePage = React.lazy(
  () => import('./Components/Admin/HomePage/index')
);
const Login = React.lazy(() => import('./Components/Login/index'));

const Router = () => {
  return (
    <React.Suspense fallback={<LoaderSpinner noText={true} />}>
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route
          path="/super-admin/manage-users"
          element={
            <ProtectedRoute roles={['SUPERADMIN']}>
              <ManageUsers />
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin/home"
          element={
            <ProtectedRoute roles={['SUPERADMIN', 'ADMIN']}>
              <AdminHomePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/manage-faq-categories"
          element={
            <ProtectedRoute roles={['SUPERADMIN', 'ADMIN']}>
              <ManageFaqCategories />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/manage-faq"
          element={
            <ProtectedRoute roles={['SUPERADMIN', 'ADMIN']}>
              <ManageFaq />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/manage-referral-bonus"
          element={
            <ProtectedRoute roles={['SUPERADMIN', 'ADMIN']}>
              <ManageReferralBonus />
            </ProtectedRoute>
          }
        />
        <Route
          path="/"
          element={
            <ProtectedRoute roles={['NEWER']}>
              <Listing />
            </ProtectedRoute>
          }
        />
        <Route
          path="/faqs"
          element={
            <ProtectedRoute roles={['NEWER']}>
              <Faqs />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-referral"
          element={
            <ProtectedRoute roles={['NEWER']}>
              <MyReferral />
            </ProtectedRoute>
          }
        />
        <Route
          path="/jobDescription/:jobId"
          element={
            <ProtectedRoute roles={['NEWER']}>
              <JobDescription />
            </ProtectedRoute>
          }
        />
        <Route
          path="/login"
          element={
            <ProtectedRoute roles={['NEWER', 'ADMIN', 'SUPER_ADMIN']}>
              <Login />
            </ProtectedRoute>
          }
        />
      </Routes>
    </React.Suspense>
  );
};
export default Router;
