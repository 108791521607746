import React, { useEffect, useState } from 'react';
import LoaderSpinner from '../../Common/LoaderSpinner';
import { userService } from '../../../Services/userService';
import { User } from '../../../Types/user';
import DisplayCount from '../../Common/DisplayCount';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Col, Pagination, PaginationProps, Table } from 'antd';
import './index.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';

interface IUser {
  key: number;
  name: string;
  email: string;
  role: string;
}
interface SuperAdminHomeProps {
  roleUpdated: boolean;
}

const SuperAdminHome: React.FC<SuperAdminHomeProps> = ({
  roleUpdated,
}: SuperAdminHomeProps) => {
  const [totalNewers, setTotalNewers] = useState(0);
  const [totalAdmins, setTotalAdmins] = useState(0);
  const [currentPageNewer, setCurrentPageNewer] = useState(1);
  const [currentPageAdmin, setCurrentPageAdmin] = useState(1);
  const [pageLimitNewer, setPageLimitNewer] = useState(10);
  const [pageLimitAdmin, setPageLimitAdmin] = useState(10);
  const [usersData, setUsersData] = useState<IUser[]>([]);
  const [loader, setLoader] = useState(false);
  const [userLoader, setUserLoader] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const role = searchParams.get('role');

  const navigate = useNavigate();

  useEffect(() => {
    setUserLoader(true);
    (async () => {
      const data = await getUsers();
      setUsersData(data);
      setUserLoader(false);
    })();
  }, [
    currentPageNewer,
    currentPageAdmin,
    pageLimitAdmin,
    pageLimitNewer,
    role,
    roleUpdated,
  ]);

  const getUsers = async (): Promise<IUser[]> => {
    const {
      data: { users: newersData, totalUsers: newersCount },
    } = await userService.getUsers({
      currentPage: currentPageNewer,
      pageLimit: pageLimitNewer,
      role: 'NEWER',
    });

    setTotalNewers(newersCount);

    const {
      data: { users: adminsData, totalUsers: adminsCount },
    } = await userService.getUsers({
      currentPage: currentPageAdmin,
      pageLimit: pageLimitAdmin,
      role: 'ADMIN',
    });
    setTotalAdmins(adminsCount);

    const data = (role === 'admin' ? adminsData : newersData)?.map(
      (user: User) => {
        return {
          key: user?.id,
          name: user?.name,
          email: user?.email,
          role: user?.role,
        };
      }
    );
    return data;
  };

  const onShowSizeChange: PaginationProps['onShowSizeChange'] = (
    current,
    pageSize
  ) => {
    role === 'newer'
      ? setPageLimitNewer(pageSize)
      : setCurrentPageAdmin(pageSize);
  };

  const DisplayCounterArray = [
    {
      title: `Total Newers`,
      count: totalNewers,
      url: `/super-admin/manage-users?role=newer&count=${totalNewers}`,
    },
    {
      title: `Total Admins`,
      count: totalAdmins,
      url: `/super-admin/manage-users?role=admin&count=${totalAdmins}`,
    },
  ];

  let columns: ColumnsType<any> = [
    {
      title: <div className="title">Name</div>,
      dataIndex: 'name',
      key: 'name',
      width: '35%',
    },
    {
      title: <div className="title">Email Id</div>,
      dataIndex: 'email',
      key: 'email',
      width: '35%',
    },
    {
      title: <div className="action">Role</div>,
      dataIndex: 'role',
      key: 'role',
      width: '30%',
    },
  ];

  if (loader) {
    return <LoaderSpinner noText={true} />;
  }

  return (
    <>
      <div className="super-admin-home-wrapper">
        {role && (
          <>
            <span
              className="go-back"
              onClick={() => navigate('/super-admin/manage-users')}
            >
              <ArrowLeftOutlined className="arrow" /> BACK
            </span>
            <Table
              columns={columns}
              dataSource={usersData}
              scroll={{ x: 600, y: 555 }}
              loading={{
                indicator: <LoaderSpinner noText={true} />,
                spinning: userLoader,
              }}
              className="table-paginated "
              pagination={false}
            />

            <Pagination
              defaultCurrent={
                role === 'admin' ? currentPageAdmin : currentPageNewer
              }
              defaultPageSize={
                role === 'admin' ? pageLimitAdmin : pageLimitNewer
              }
              total={role === 'newer' ? totalNewers : totalAdmins}
              onChange={(page) => {
                role === 'admin'
                  ? setCurrentPageAdmin(page)
                  : setCurrentPageNewer(page);
              }}
              onShowSizeChange={onShowSizeChange}
              responsive={true}
              showSizeChanger={true}
              showTotal={(total) => `Total ${total} records`}
            />
          </>
        )}

        <div className="super-admin-home-display-counter">
          {!role && DisplayCounterArray && DisplayCounterArray.length
            ? DisplayCounterArray.map((item, idx) => (
                <DisplayCount
                  key={idx}
                  count={item.count}
                  title={item.title}
                  url={item.url}
                />
              ))
            : null}
        </div>
      </div>
    </>
  );
};

export default SuperAdminHome;
