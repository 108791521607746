export const HomeIcon = (props: any) => {
  const { color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path fill="none" d="M0 0h20v20H0z"></path>
      <path
        fill={color ? color : "#de1186"}
        d="M14.053 15.5H.827A.828.828 0 010 14.677v-8.7a.824.824 0 01.319-.654L6.932.174a.826.826 0 011.015 0l6.613 5.15a.824.824 0 01.319.654v8.7a.828.828 0 01-.826.822zm-7.44-6.617v4.966h1.653V8.883z"
        transform="translate(2.56 1.935)"
      ></path>
    </svg>
  )
};

export const MyReferral = (props: any) => {
  const { color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path fill="none" d="M0 0h20v20H0z"></path>
      <rect
        width="5.685"
        height="7.322"
        fill="none"
        stroke={color ? color : "#2e1c41"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        data-name="Rectangle 574"
        rx="2.843"
        transform="translate(7.16 1.275)"
      ></rect>
      <path
        fill="none"
        stroke={color ? color : "#2e1c41"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12.641 10.648H6.784a2.637 2.637 0 00-2.77 2.483v3.987a1.594 1.594 0 001.674 1.5h8.626a1.594 1.594 0 001.674-1.5v-3.471a3.186 3.186 0 00-3.347-2.999z"
        data-name="Path 1215"
      ></path>
    </svg>
  )
};

export const ClaimBonus = (props: any) => {
  const { color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path fill="none" d="M0 0h20v20H0z"></path>
      <path
        fill="none"
        d="M16.678 5.423a2.086 2.086 0 000 4.171v4.588a.834.834 0 01-.834.834H.834A.834.834 0 010 14.182V.834A.834.834 0 01.834 0h15.01a.834.834 0 01.834.834z"
        data-name="Path"
        transform="translate(1.661 2.492)"
      ></path>
      <path
        fill={color ? color : "#2e1c41"}
        d="M15.766 14.943H.83a.831.831 0 01-.83-.83V.83A.831.831 0 01.83 0h14.936a.831.831 0 01.83.83V5.4a2.076 2.076 0 000 4.151v4.566a.831.831 0 01-.83.826zM6.223 12.45a1.248 1.248 0 011.175.832h7.538v-2.464a3.736 3.736 0 010-6.694V1.66H7.4a1.245 1.245 0 01-2.349 0H1.659v11.622h3.39a1.247 1.247 0 011.174-.832zm0-1.659a1.245 1.245 0 111.245-1.244 1.246 1.246 0 01-1.245 1.245zm0-4.15A1.245 1.245 0 117.468 5.4a1.246 1.246 0 01-1.245 1.241z"
        transform="translate(1.378 2.48)"
      ></path>
    </svg>
  )
};

export const ReferralPolicy = (props: any) => {
  const { color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path fill="none" d="M0 0h20v20H0z"></path>
      <path
        fill={color ? color : "#2e1c41"}
        d="M5.713 16.814a5.714 5.714 0 01-4.008-9.787l1.347-1.346L4.4 7.027 3.052 8.374a3.809 3.809 0 005.387 5.387l1.346-1.347 1.346 1.347-1.346 1.346a5.746 5.746 0 01-4.072 1.707zm.033-4.4L4.4 11.066l6.733-6.732L12.48 5.68l-6.733 6.732zm8.08-1.346L12.479 9.72l1.347-1.346a3.809 3.809 0 00-5.387-5.387L7.092 4.334 5.746 2.987 7.092 1.64a5.713 5.713 0 018.08 8.08l-1.346 1.346z"
        transform="translate(1.565 1.623)"
      ></path>
    </svg>
  )
};

export const FaqIcon = (props: any) => {
  const { color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.5"
      height="17.5"
      viewBox="0 0 17.5 17.5"
    >
      <g
        fill="none"
        stroke={color ? color : "#423254"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        transform="translate(-1.25 -1.25)"
      >
        <circle
          cx="8"
          cy="8"
          r="8"
          data-name="Ellipse 964"
          transform="translate(2 2)"
        ></circle>
        <path
          d="M7.655 7.583a2.393 2.393 0 014.65.8c0 1.6-2.393 2.393-2.393 2.393"
          data-name="Path 51782"
        ></path>
        <path
          d="M0 0L0.008 0"
          data-name="Line 464"
          transform="translate(9.976 13.964)"
        ></path>
      </g>
    </svg>
  )
};