import { useLocation } from 'react-router-dom';
import './index.scss';

const MobileNavigation = (props: {
  tabs: any;
  onTabChange: any;
  userData: any;
}) => {
  const { tabs, onTabChange, userData } = props;
  const location = useLocation();

  return (
    <div className="ant-tabs mobile-navigation">
      <div className="ant-tabs-nav">
        <div className="ant-tabs-nav-wrap">
          <div className="ant-tabs-nav-list">
            {tabs.map((tab: any, value: number) => {
              return (
                tab.permission &&
                Array.isArray(tab.permission) &&
                tab.permission.includes(userData?.role) && (
                  <div
                    className={`ant-tabs-tab ${
                      tab.path === location.pathname
                        ? 'ant-tabs-tab-active'
                        : ''
                    }`}
                    onClick={() => {
                      onTabChange(tab.path);
                      document
                        .querySelectorAll<HTMLElement>(
                          'body, #root, .App, .App > div'
                        )
                        .forEach((node) => (node.style.height = ''));
                    }}
                  >
                    <div className="ant-tabs-tab-btn">
                      <span>
                        {tab.icon}
                        {tab.value}
                      </span>
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileNavigation;
