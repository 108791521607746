import { AxiosService } from '../apiService';
import GlobalConfig from '../globalConfig';
import { getApi } from '../repository';

class NotificationServices {

  getNotifications() {
    const requestObj: any = { ...getApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/referrals/notifications`;
    const api = new (AxiosService as any)(requestObj);
    return api.doAjax();
  }
}
export const notificationServices = new NotificationServices();
