import { useEffect, useState } from 'react';
import { Button, Form, Row, Col, Modal, message } from 'antd';
import FloatLabel from '../../../Common/FloatLabel';
import { faqCategoryService } from '../../../../Services/faqCategoryService';
import { errorToast } from "../../../Common/ToastService";
import './index.scss';

// @ts-ignore
const CreateFaqCategory = (props: any) => {
  const {
    isAddNewFaqCategory,
    setIsAddNewFaqCategory,
    setIsReload,
    isReload,
    currentItem,
    isUpdate,
  } = props;
  const [form] = Form.useForm();
  const [modalLoader, setModalLoader] = useState(true);

  useEffect(() => {
    if (isUpdate) {
      form.setFieldValue('categoryName', currentItem.name);
      setModalLoader(false);
    } else {
      form.setFieldValue('categoryName', undefined);
      setModalLoader(false);
    }
  }, [isUpdate]);

  const validator = {
    require: {
      required: true,
      message: 'Required',
    },
  };

  const onFormSubmit = async (values: any) => {
    const formData = {
      categoryName: values.categoryName,
    };

    let response;
    try {
      if (!isUpdate) {
        response = await faqCategoryService.createFaqCategory(formData);
      } else {
        response = await faqCategoryService.updateFaqCategory(formData, currentItem.id);
      }
      if (response) {
        form.resetFields();
        setIsAddNewFaqCategory(false);
        setIsReload(!isReload);
      }
    } catch (error: any) {
      errorToast(error.response.data.error);
    }
  };

  if (modalLoader) {
    return <p></p>;
  }

  return (
    <>
      {!isUpdate ? (
        <Modal
          title={'Add FAQ Category'}
          className="create-faq-category-form"
          open={isAddNewFaqCategory}
          onCancel={() => {
            form.resetFields();
            setIsAddNewFaqCategory(false);
          }}
          footer={null}
          width={330}
          destroyOnClose={true}
        >
          <Form form={form} onFinish={onFormSubmit}>
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                <Form.Item name="categoryName" rules={[validator.require]} hasFeedback>
                  <FloatLabel
                    label="Category Name"
                    placeholder="Category Name"
                    name="categoryName"
                    type="input"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button className="create-faq-btn" htmlType="submit">
                Create FAQ Category
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      ) : (
        <Modal
          title={'Update FAQ Category'}
          className="create-faq-category-form"
          open={isAddNewFaqCategory}
          onCancel={() => {
            form.resetFields();
            setIsAddNewFaqCategory(false);
          }}
          footer={null}
          width={330}
          destroyOnClose={true}
        >
          <Form
            form={form}
            onFinish={onFormSubmit}
            initialValues={{ categoryName: currentItem.name }}
          >
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                <Form.Item name="categoryName" rules={[validator.require]} hasFeedback>
                  <FloatLabel
                    label="Category Name"
                    placeholder="Category Name"
                    name="categoryName"
                    type="input"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button className="create-faq-btn" htmlType="submit">
                Update FAQ Category
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default CreateFaqCategory;
