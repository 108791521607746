import Cookies from 'js-cookie';
import { AxiosService } from '../apiService';
import { DOMAIN } from '../Constants';
import GlobalConfig from '../globalConfig';
import { getApi } from '../repository';

class LoginServices {

  login(){
    const requestObj: any = { ...getApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/auth/google`;
    const api = new (AxiosService as any)(requestObj);
    return api.doAjax();
  }

  logout(){
    Cookies.remove('referral_auth_token', { domain: DOMAIN, path: '/' });
    localStorage.removeItem('referral-user-data');
    localStorage.removeItem('jobsInfo');
  }
}
export const loginServices = new LoginServices();
