import React, { HtmlHTMLAttributes, useEffect, useState } from 'react';
import { Table, Button, Popconfirm } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import LoaderSpinner from '../../Common/LoaderSpinner';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import CreateFAQ from './FaqCategoryForm';
import '../../Common/Table/table.scss';
import './index.scss';
import { faqCategoryService } from '../../../Services/faqCategoryService';
import { errorToast } from "../../Common/ToastService";
import { FilterValue } from 'antd/es/table/interface';
import { MenuOutlined } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface IFaqCategory {
  key: number;
  name: string;
  action: HtmlHTMLAttributes<any>;
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 1 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'move' }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};



const ManageFaqCategories: React.FC = () => {
  const [loader, setLoader] = useState(false);
  const [faqCategories, setFaqCategories] = useState<IFaqCategory[]>([]);
  const currentPage = 1;
  const pageLimit = 100;
  const [isAddNewFaqCategory, setIsAddNewFaqCategory] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [sortedInfo, setSortedInfo] = useState<any>({});

  useEffect(() => {
    if (!faqCategories.length) {
      (async () => {
        const data = await getFaqCategories();
        setFaqCategories(data);
      })();
    }
  }, []);

  useEffect(() => {
    (async () => {
      const data = await getFaqCategories();
      setFaqCategories(data);
    })();
  }, [isReload, currentPage, pageLimit]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setFaqCategories((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        const categoriesData = arrayMove(previous, activeIndex, overIndex);
        console.log(categoriesData, 'ccc===')
        updateOrder(categoriesData);
        return categoriesData;
      });
    }
  };

  const updateOrder = (categoriesData: { key: number, name: string }[]) => {
    const data = categoriesData.map((category: { key: number, name: string }, i: number) => ({ order: i + 1, id: category.key, name: category.name }));
    // update categories
    console.log(data)
    faqCategoryService.updateOrder(data).then(() => {
      console.log('order saved');
    }).catch((e: any) => {
      console.log('Error while updating order');
    })
  }

  const deleteFaqCategory = async (id: number) => {
    setLoader(true);
    setIsReload((p) => !p);
    try {
      await faqCategoryService.deleteFaqCategory(id);
    } catch (error: any) {
      errorToast(error.response.data.error);
    }
    setIsReload((p) => !p);
  };

  const getFaqCategories = async (): Promise<IFaqCategory[]> => {
    setLoader(true);
    const response = await faqCategoryService.getFaqCategories({ currentPage, pageLimit });
    const { faqCategories } = response.data;
    // setTotalFaqCategories(faqCategoriesCount);
    const faqCategoriesData = faqCategories.map((item: any) => {
      return {
        key: item.id,
        name: item.name,
        action: (
          <div className="actions">
            <div
              className="edit"
              onClick={() => {
                setIsAddNewFaqCategory(true);
                setIsUpdate(true);
                setCurrentItem(item);
              }}
            >
              <EditOutlined />
            </div>
            <div className="delete">
              <Popconfirm
                title="Delete FAQ"
                description="Are you sure to delete this FAQ?"
                onConfirm={() => deleteFaqCategory(item.id)}
                onCancel={() => console.log('cancel')}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined />
              </Popconfirm>
            </div>
          </div>
        ),
      };
    });
    setLoader(false);
    return faqCategoriesData;
  };

  const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: any) => {
    console.log(sorter);
    setSortedInfo(sorter);
  }

  let columns: ColumnsType<any> = [
    {
      key: 'sort',
    },
    {
      title: (
        <div className="title">
          Category Name
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
    },
    {
      title: <div className="action">Action</div>,
      dataIndex: 'action',
      key: 'action',
      fixed: 'left',
    },
  ];

  if (loader) {
    return <LoaderSpinner noText={true} />;
  }

  return (
    <div className="faq-page faq-category-page">
      <div className="faq-title">
        <h2>Manage FAQ Categories</h2>
        <Button
          className="add-faq-btn"
          onClick={() => {
            setIsAddNewFaqCategory(true);
            setIsUpdate(false);
          }}
        >
          Add FAQ Category
        </Button>
      </div>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          items={faqCategories.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: {
                row: Row,
              },
            }}
            columns={columns}
            dataSource={faqCategories}
            className="table-paginated faq"
            pagination={false}
            onChange={handleTableChange}
            rowKey="key"
          />
        </SortableContext>
      </DndContext>
      
      {
        isAddNewFaqCategory ?
          <CreateFAQ
            isAddNewFaqCategory={isAddNewFaqCategory}
            setIsAddNewFaqCategory={setIsAddNewFaqCategory}
            setIsReload={setIsReload}
            isReload={isReload}
            currentItem={currentItem}
            isUpdate={isUpdate}
          />
          : null
      }
    </div>
  );
};

ManageFaqCategories.defaultProps = {};

export default ManageFaqCategories;
