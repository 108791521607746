// import axios from 'axios';

const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

export const serialize = (obj: any) => {
  const str: any = [];
  Object.keys(obj).forEach((p) => {
    str.push(
      `${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`,
    );
  });
  return str.join('&');
};

export const replaceStringWithParams = (str: string, obj: any) => {
  Object.keys(obj).forEach((j) => {
    const regExp = new RegExp(j, 'g');
    str = str.replace(regExp, obj[j]);
  });
  return str;
};

export const formatDate = (date: string) => {
  const d = new Date(date);

  var yyyy = d.getFullYear().toString();
  var mm = (d.getMonth()+1);
  var dd  = d.getDate().toString();

  var ddChars = dd.split('');

  return (ddChars[1]?dd:"0"+ddChars[0]) + ' ' + months[mm-1] + ' ' + yyyy;
}

export const truncate = (str: string, n: number) => {
  return str?.length > n ? str.substr(0, n - 1) + '...' : str;
};