import React, { HtmlHTMLAttributes, useEffect, useState } from 'react';
import {
  Table,
  Pagination,
  Button,
  PaginationProps,
  Popconfirm,
} from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import LoaderSpinner from '../../Common/LoaderSpinner';
import { faqService } from '../../../Services/faqService';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import CreateFAQ from './FaqForm';
import '../../Common/Table/table.scss';
import { errorToast } from '../../Common/ToastService';
import './index.scss';
import { FilterValue } from 'antd/es/table/interface';

interface IFaq {
  key: number;
  question: string;
  action: HtmlHTMLAttributes<any>;
}

const ManageFaq: React.FC = () => {
  const [loader, setLoader] = useState(false);
  const [faqs, setFaqs] = useState<IFaq[]>([]);
  const [totalFaqs, setTotalFaqs] = useState(0);
  const [currentPage, setCurrentpage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [isAddNewFaq, setIsAddNewFaq] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [sortedInfo, setSortedInfo] = useState<any>({});

  useEffect(() => {
    if (!faqs.length) {
      (async () => {
        const data = await getFaqs();
        setFaqs(data);
      })();
    }
  }, []);

  useEffect(() => {
    (async () => {
      const data = await getFaqs();
      setFaqs(data);
    })();
  }, [isReload, currentPage, pageLimit]);

  const onShowSizeChange: PaginationProps['onShowSizeChange'] = (
    current,
    pageSize
  ) => {
    setPageLimit(pageSize);
  };

  const deleteFaq = async (id: number) => {
    setLoader(true);
    setIsReload(true);
    try {
      await faqService.deleteFaq(id);
    } catch (error: any) {
      errorToast(error.response.data.error);
    }
    setIsReload(false);
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any
  ) => {
    console.log(sorter);
    setSortedInfo(sorter);
  };

  console.log(isAddNewFaq, 'isAddNewFaq');

  const getFaqs = async (): Promise<IFaq[]> => {
    setLoader(true);
    const response = await faqService.getFaqs({ currentPage, pageLimit });
    const { faqs, totalFaqs: faqsCount } = response.data;
    setTotalFaqs(faqsCount);
    const faqsData = faqs.map((item: any) => {
      return {
        key: item.id,
        question: item.question,
        action: (
          <div className="actions">
            <div
              className="edit"
              onClick={() => {
                setIsAddNewFaq(true);
                setIsUpdate(true);
                setCurrentItem(item);
              }}
            >
              <EditOutlined />
            </div>
            <div className="delete">
              <Popconfirm
                title="Delete FAQ"
                description="Are you sure to delete this FAQ?"
                onConfirm={() => deleteFaq(item.id)}
                onCancel={() => console.log('cancel')}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined />
              </Popconfirm>
            </div>
          </div>
        ),
      };
    });
    setLoader(false);
    return faqsData;
  };

  let columns: ColumnsType<any> = [
    {
      title: <div className="title">Question</div>,
      dataIndex: 'question',
      key: 'question',
      fixed: 'left',
      sorter: (a: any, b: any) => a.question.localeCompare(b.question),
      sortOrder: sortedInfo.columnKey === 'question' && sortedInfo.order,
    },
    {
      title: <div className="action">Action</div>,
      dataIndex: 'action',
      key: 'action',
      fixed: 'left',
    },
  ];

  if (loader) {
    return <LoaderSpinner noText={true} />;
  }

  return (
    <div className="faq-page manage-faq-page">
      <div className="faq-title">
        <h2>Manage FAQ</h2>
        <Button
          className="add-faq-btn"
          onClick={() => {
            setIsAddNewFaq(true);
            setIsUpdate(false);
            console.log('add faq');
          }}
        >
          Add FAQ
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={faqs}
        className="table-paginated faq"
        pagination={false}
        onChange={handleTableChange}
      />
      <Pagination
        defaultCurrent={currentPage}
        defaultPageSize={pageLimit}
        total={totalFaqs}
        onChange={(page) => setCurrentpage(page)}
        onShowSizeChange={onShowSizeChange}
        responsive={true}
        showSizeChanger={true}
        showTotal={(total) => `Total ${total} records`}
      />

      {isAddNewFaq ? (
        <CreateFAQ
          isAddNewFaq={isAddNewFaq}
          setIsAddNewFaq={setIsAddNewFaq}
          setIsReload={setIsReload}
          isReload={isReload}
          currentItem={currentItem}
          isUpdate={isUpdate}
        />
      ) : null}
    </div>
  );
};

ManageFaq.defaultProps = {};

export default ManageFaq;
