import { AxiosService } from '../apiService';
import GlobalConfig from '../globalConfig';
import { getApi, postApi, putApi, deleteApi } from '../repository';

class FaqCategoryService {
  getFaqCategories({ currentPage, pageLimit }: { currentPage?: number; pageLimit?: number }) {
    const requestObj: any = { ...getApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/faqs/category?${currentPage ? 'page=' + currentPage : ''}${
      pageLimit ? '&limit=' + pageLimit : ''
    }`;
    const api = new (AxiosService as any)(requestObj);
    return api.doAjax();
  }

  createFaqCategory(params: object) {
    const requestObj: any = { ...postApi };
    requestObj.params = params;
    requestObj.url = `${GlobalConfig.API_ROOT}/faqs/category`;
    const api = new (AxiosService as any)(requestObj);
    return api.doAjax();
  }

  updateFaqCategory(params: object, id: number) {
    const requestObj: any = { ...putApi };
    requestObj.params = params;
    requestObj.url = `${GlobalConfig.API_ROOT}/faqs/category/${id}`;
    const api = new (AxiosService as any)(requestObj);
    return api.doAjax();
  }

  deleteFaqCategory(id: number) {
    const requestObj: any = { ...deleteApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/faqs/category/${id}`;
    const api = new (AxiosService as any)(requestObj);
    return api.doAjax();
  }

  updateOrder(params: object) {
    const requestObj: any = { ...putApi };
    requestObj.params = params;
    requestObj.url = `${GlobalConfig.API_ROOT}/faqs/order`;
    const api = new (AxiosService as any)(requestObj);
    return api.doAjax();
  }
}
export const faqCategoryService = new FaqCategoryService();
