import axios from 'axios';
import { get } from 'lodash';
import Cookies from 'js-cookie';
import { loginServices } from './Services/loginServices';

const httpService = {
  setupInterceptors: () => {
    axios.interceptors.request.use(
      function (config) {
        const token = Cookies.get('referral_auth_token');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      function (err) {
        return Promise.reject(err);
      },
    );

    axios.interceptors.response.use(
      (response) => {
        if (get(response, 'headers.accesstoken')) {
          localStorage.setItem('token',`Bearer ${get(response, 'headers.accesstoken')}`);
        }
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        if(error.response.status === 403) {
          loginServices.logout();
          window.location.href = '/login';
        }
        if (error.response.status === 401 && originalRequest['url'].includes('login')) {
          // logoutHandler();
          // window.location.href = '/sessionExpired/true';
          console.log('Response status code 401');
        }
        if (error.response.status === 401 && !originalRequest._retry) {
          console.log('Response status code 401');
        }
        if (error.response && error.response.status === 406) {
          // logoutHandler();
          // window.location.href = '/sessionExpired/true';
          console.log('Response status code 406');
        }
        return Promise.reject(error);
      },
    );
  },
};

export default httpService;
