import axios from 'axios';
import { serialize, replaceStringWithParams } from './commonUtils';

declare global {     
  interface Window {         
    axios_source: any;     
  } 
}

interface IProps {
  obj: object;
  headers: any,
  params: any,
  method: any,
  url: string,
  isSerialize: any,
  setHeaders: any,
  setParams: any,
  get: any,
  post: any,
  all: any,
  doAjax: any,
  this: any,
}

export function AxiosService(this: any, obj: any , props: IProps) {
  const _that = this;

  (function __construct(obj: any) {
    _that.headers = obj.headers;
    _that.params = obj.params || {};
    _that.method = obj.method.toLowerCase();
    _that.url = obj.url;
    _that.isSerialize = obj.isSerialize || null;
  }(obj));

  this.setHeaders = setHeaders;
  this.setParams = setParams;
  this.get = handleGet;
  this.post = handlePost;
  this.all = handleAll;
  this.doAjax = doAjax;

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  if(!window.axios_source){
    window.axios_source = [];
  }
  window.axios_source.push(source);

  function doAjax() {
    try {
      delete _that.params.catalog_tag_filter;
    } catch (e) {
      console.log('Error api service', e);
    }


    const data = _that.params;
    const convertParamsToUpdateUrl: any = {};
    Object.keys(data).forEach(i => {
      convertParamsToUpdateUrl[`{${i}}`] = data[i];
    });
    

    let url = _that.url;
    if (_that.method === 'get') {
      if (_that.params && Object.keys(_that.params).length > 0) {
        url = replaceStringWithParams(_that.url, convertParamsToUpdateUrl);
        url += `?${serialize(_that.params)}`;
      }
    } 

    return axios({
      method: _that.method,
      url,
      headers: Object.assign({}, _that.headers), // weired cannot directly pass _that.headers as it doesn't reflect, need to figure it out later.
      data,
      cancelToken: source.token,
    });
  }

  function setParams(this: any, obj: any) {
    if (obj) {
      Object.keys(obj).forEach(i => {
        this.params[i] = obj[i];
      });
    }
  }

  function setHeaders(this: any, key: any, val: any) {
    this.headers[key] = val;
  }

  function handleGet(this: any) {
    this.doAjax('get');
  }

  function handlePost(this: any) {
    this.doAjax('post');
  }

  function handleAll(promises: any) { 
    // promises will be promise objects array e.g. [axios.get('/user'), axios.get('/comments')]
    return axios.all(promises);
  }
}