import { useState, useEffect } from 'react';
import { Button, Form, Row, Col, Modal } from 'antd';
import FloatLabel from '../../../Common/FloatLabel';
import { faqCategoryService } from '../../../../Services/faqCategoryService';
import { faqService } from '../../../../Services/faqService';
import LoaderSpinner from '../../../Common/LoaderSpinner';
import { errorToast } from '../../../Common/ToastService';
import './index.scss';

// @ts-ignore
const CreateFAQ = (props: any) => {
  const {
    isAddNewFaq,
    setIsAddNewFaq,
    setIsReload,
    isReload,
    currentItem,
    isUpdate,
  } = props;
  const [form] = Form.useForm();
  const [categories, setCategories] = useState<
    { label: string; value: number }[]
  >([]);
  const [initCategory, setInitCategory] = useState<any>({});
  const [loader, setLoader] = useState(false);
  const [textEditvalue, setEditorValue] = useState('');
  const [isNoCategory, setIsNoCategory] = useState(true);

  const validator = {
    require: {
      required: true,
      message: 'Required',
    },
  };

  const onFormSubmit = async (values: any) => {
    const formData = {
      categoryId: values.category,
      question: values.question,
      answer: values.answer,
    };

    let response;
    try {
      if (!isUpdate) {
        response = await faqService.createFaq(formData);
      } else {
        response = await faqService.updateFaq(formData, currentItem.id);
      }
      if (response) {
        form.resetFields();
        setIsAddNewFaq(false);
        setIsReload(!isReload);
      }
    } catch (error: any) {
      errorToast(error.response.data.error);
    }
  };

  useEffect(() => {
    const initialCategory = categories.find(
      (item: any) => item.value === currentItem.categoryId
    )!;
    if (initialCategory) {
      setInitCategory(initialCategory);
    }

    //get faqCategory data
    (async () => {
      if (!categories.length && isNoCategory) {
        const faqCategoryData = await getFaqsCategories();
        if(!faqCategoryData.length) {
          setIsNoCategory(false);
        }
        setCategories(faqCategoryData);
      }
    })();
  }, [currentItem, categories, isNoCategory]);

  useEffect(() => {
    setLoader(true);
    if (isUpdate) {
      form.setFieldsValue({
        categoryName: initCategory.label,
        category: initCategory.value,
        question: currentItem.question,
        answer: currentItem.answer,
      });
      setEditorValue(currentItem.answer);
      setLoader(false);
    } else {
      form.setFieldsValue({
        categoryName: undefined,
        category: undefined,
        question: undefined,
        answer: undefined,
      });
      setEditorValue('');
      setLoader(false);
    }
  }, [isUpdate, initCategory, currentItem]);

  const handleTextEditor = (value: string) => {
    if (value === '<p><br></p>') {
      setEditorValue('');
      form.setFieldValue('answer', '');
    } else {
      setEditorValue(value);
      form.setFieldValue('answer', value);
    }
  };

  const getFaqsCategories = async () => {
    setLoader(true);
    const { data } = await faqCategoryService.getFaqCategories({
      currentPage: 1,
      pageLimit: 100,
    });
    const { faqCategories } = data;
    const newFaqCategoryData = faqCategories.map((item: any) => {
      return { label: item.name, value: item.id };
    });
    setLoader(false);
    return newFaqCategoryData;
  };

  if (loader) {
    return <LoaderSpinner noText={true} />;
  }

  return (
    <>
      {!isUpdate ? (
        <Modal
          title={'Add FAQ'}
          className="create-faq-form"
          open={isAddNewFaq}
          onCancel={() => {
            form.resetFields();
            setIsAddNewFaq(false);
          }}
          footer={null}
          width={800}
          centered={true}
          destroyOnClose={true}
        >
          <Form form={form} onFinish={onFormSubmit}>
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                <Form.Item
                  hasFeedback
                  name="category"
                  rules={[validator.require]}
                >
                  <FloatLabel
                    label="Category"
                    placeholder="Category"
                    name="category"
                    type="select"
                    optionsArray={categories}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                <Form.Item
                  name="question"
                  rules={[validator.require]}
                  hasFeedback
                >
                  <FloatLabel
                    label="Question"
                    placeholder="Question"
                    name="question"
                    type="input"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="answer-text">
              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                <Form.Item
                  name="answer"
                  rules={[validator.require]}
                  hasFeedback
                >
                  <FloatLabel
                    label="Answer"
                    name="answer"
                    type="texteditor"
                    onChange={handleTextEditor}
                    textEditvalue={textEditvalue}
                    placeholder={'Write your answer...'}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item wrapperCol={{ span: 24 }} className="faq-btn-wrapper">
              <Button className="create-faq-btn" htmlType="submit">
                Create FAQ
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      ) : (
        <Modal
          title={'Update FAQ'}
          className="create-faq-form"
          open={isAddNewFaq}
          onCancel={() => {
            form.resetFields();
            setIsAddNewFaq(false);
          }}
          footer={null}
          width={800}
          centered={true}
          destroyOnClose={true}
        >
          {initCategory && initCategory.label && (
            <Form
              form={form}
              initialValues={{
                categoryName: initCategory.label,
                category: initCategory.value,
                question: currentItem.question,
                answer: currentItem.answer,
              }}
              onFinish={onFormSubmit}
            >
              <Row>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                  <Form.Item
                    hasFeedback
                    name="category"
                    rules={[validator.require]}
                  >
                    <FloatLabel
                      label="Category"
                      placeholder="Category"
                      name="categoryName"
                      type="select"
                      value={initCategory.value}
                      optionsArray={categories}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                  <Form.Item
                    name="question"
                    rules={[validator.require]}
                    hasFeedback
                  >
                    <FloatLabel
                      label="Question"
                      placeholder="Question"
                      name="question"
                      type="input"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="answer-text">
                <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                  <Form.Item
                    name="answer"
                    rules={[validator.require]}
                    hasFeedback
                  >
                    <FloatLabel
                      label="Answer"
                      name="answer"
                      type="texteditor"
                      onChange={handleTextEditor}
                      textEditvalue={textEditvalue}
                      placeholder={'Write your answer...'}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item wrapperCol={{ span: 24 }} className="faq-btn-wrapper">
                <Button className="create-faq-btn" htmlType="submit">
                  Update FAQ
                </Button>
              </Form.Item>
            </Form>
          )}
        </Modal>
      )}
    </>
  );
};

export default CreateFAQ;
