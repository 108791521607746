import { useState } from 'react';
import { Select, Input } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { modules, formats } from '../TextEditorToolBar';
import '../../Common/TextEditorToolBar/index.scss';

import './index.scss';

const FloatLabel = (props) => {
  const [focus, setFocus] = useState(false);
  let {
    label,
    value,
    placeholder,
    type,
    required,
    optionsArray = [],
    className,
    maxLength,
    allowClear,
    textEditvalue,
    onKeyDown,
    disabled,
  } = props;

  if (!placeholder) placeholder = label;

  const checkIsOccupied = () => {
    if (focus) {
      return focus;
    } else if (typeof value === 'string') {
      return value && value.length > 0;
    } else if (typeof value === 'object' && Array.isArray(value)) {
      return value.length > 0;
    } else {
      return value >= 0;
    }
  };

  const isOccupied = checkIsOccupied();

  const labelClass = isOccupied ? 'label as-label' : 'label as-placeholder';

  const requiredMark = required ? <span className="text-danger">*</span> : null;

  const renderSwitch = (type = 'input') => {
    switch (type) {
      case 'input':
        return (
          <Input
            onChange={props.onChange}
            type={type}
            defaultValue={value}
            maxLength={maxLength}
            allowClear={allowClear}
            onKeyDown={onKeyDown}
          />
        );
      case 'select':
        return (
          <Select
            showSearch
            onChange={props.onChange}
            defaultValue={value}
            value={value}
            disabled={disabled}
            popupClassName="admin-float-select"
            getPopupContainer={(trigger) => trigger.parentNode}
            filterOption={(input, option) => {
              return (option?.children ?? '')
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
          >
            {optionsArray &&
              optionsArray.length &&
              optionsArray.map((item, idx) => (
                <Select.Option key={idx} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
          </Select>
        );
      case 'multiselect':
        return (
          <Select
            mode="multiple"
            maxTagCount="responsive"
            showSearch
            onChange={props.onChange}
            defaultValue={value}
            value={value}
            disabled={disabled}
            popupClassName="admin-float-select"
            getPopupContainer={(trigger) => trigger.parentNode}
            filterOption={(input, option) => {
              return (option?.children ?? '')
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
          >
            {optionsArray &&
              optionsArray.length &&
              optionsArray.map((item, idx) => (
                <Select.Option key={idx} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
          </Select>
        );
      case 'textarea':
        return (
          <Input.TextArea
            maxLength={maxLength}
            onChange={props.onChange}
            defaultValue={value}
            value={value}
            className={className}
          />
        );
      case 'texteditor':
        return (
          <ReactQuill
            theme="snow"
            value={textEditvalue}
            onChange={props.onChange}
            placeholder={placeholder}
            modules={modules}
            formats={formats}
          />
        );
      default:
        return '';
    }
  };
  return (
    <div
      className={`float-label ${className ? className : ''}`}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {renderSwitch(type)}
      <label className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
    </div>
  );
};

export default FloatLabel;
