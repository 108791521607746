import * as React from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';

interface IProps {
  count?: number;
  title?: string;
  url?: string;
}

const DisplayCount: React.FC<IProps> = (props: IProps) => {
  const navigate = useNavigate();
  return (
    <div
      className="count-wrapper"
      style={{ cursor: 'pointer' }}
      onClick={props.url ? () => navigate(props.url || '') : undefined}
    >
      <h2 className="count">{props.count}</h2>
      <span className="title">{props.title}</span>
    </div>
  );
};

DisplayCount.defaultProps = {
  count: 0,
  title: '',
  url: '',
};

export default DisplayCount;
