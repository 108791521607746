import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import './App.css';
import Header from './Components/Header';
import Navigation from './Components/Navigation';
import Router from './router';
import { GlobalContext } from './Context/GlobalContext';
import { AppContract, genericVerbiage } from './Types/app';
import httpService from './axios-interceptor';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { useNavigate, useLocation } from 'react-router-dom';

httpService.setupInterceptors();

function App() {
  const [appDetails, setAppDetails] = useState<AppContract>({
    userName: genericVerbiage.noTitle,
    isAuthenticated: false,
    doLogin: () => {
      return null;
    },
  });

  const navigate = useNavigate();
  const location = useLocation();
  const myCookieValue = Cookies.get('referral_auth_token');
  let userData: any;
  if (myCookieValue) {
    userData = jwt_decode(myCookieValue as string);
  }

  const [isSideBarCollapsed, setSideBarCollapsed] = useState(false);

  const doLogin = () => {
    setAppDetails({ ...appDetails, isAuthenticated: true });
  };

  useEffect(() => {
    let _usersData = localStorage.getItem('referral-user-data');
    if (!myCookieValue) {
      navigate('/login');
    }
    else if(location.pathname === "/login"){
      navigate('/');
    }
    if (myCookieValue && !_usersData) {
      localStorage.setItem('referral-user-data', JSON.stringify(userData));
      if (userData.role === 'ADMIN') {
        navigate('/admin/home');
      } else {
        navigate('/');
      }
    }
    setAppDetails({
      ...appDetails,
      userName: genericVerbiage.pageTitle,
      doLogin,
    });
  }, [location.pathname]);

  return (
    <div className="App">
      <div>
        <GlobalContext.Provider value={appDetails}>
          {myCookieValue && <Header setSideBarCollapsed={setSideBarCollapsed} isSideBarCollapsed={isSideBarCollapsed} />}
          <Row style={{ backgroundColor: '#FAFAFA', flexFlow: 'unset' }} className={"navigation-wrapper" + (location.pathname === "/login" ? " nav-height" : '' )}>
            {
              location.pathname !== "/login" ?
              <Col lg={4} sm={24} className="nav-wrap">
                {myCookieValue && (
                  <Navigation isSideBarCollapsed={isSideBarCollapsed} setSideBarCollapsed={setSideBarCollapsed} userData={userData} />
                )}
              </Col> : null
            }
            {!isSideBarCollapsed ? <Router /> : null}
          </Row>
        </GlobalContext.Provider>
      </div>
    </div>
  );
}

export default App;
