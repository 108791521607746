import React, { useEffect, useRef, useState } from 'react';
import { Table, Pagination, PaginationProps, Select, Input } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import LoaderSpinner from '../../Common/LoaderSpinner';
import '../../Common/Table/table.scss';
import './index.scss';
import { FilterValue } from 'antd/es/table/interface';
import { errorToast, successToast } from '../../Common/ToastService';
import { TOAST_MESSAGES } from '../../../Constants';
import { CloseCircleFilled } from '@ant-design/icons';
import { userService } from '../../../Services/userService';
import { User } from '../../../Types/user';
import SuperAdminHome from '../SuperAdminHome';
import { useSearchParams } from 'react-router-dom';

interface IUser {
  key: number;
  name: string;
  email: string;
  action: JSX.Element;
}

const ManageUsers: React.FC = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [currentPage, setCurrentpage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [usersData, setUsersData] = useState<IUser[]>([]);
  const [loader, setLoader] = useState(false);
  const [userLoader, setUserLoader] = useState(false);
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [searchKeyword, setSearchKeyword] = useState('');
  const [roleUpdated, setRoleUpdated] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const role = searchParams.get('role');

  const roleRef = useRef<any>();
  roleRef.current = roleUpdated;

  useEffect(() => {
    setUserLoader(true);
    (async () => {
      const data = await getUsers();
      setUsersData(data);
      setUserLoader(false);
    })();
  }, [currentPage, pageLimit, searchKeyword]);

  const handleSearch = async (val: string) => {
    setSearchKeyword(val);
  };

  const handleRoleChange = async (value: string, user: User) => {
    setUserLoader(true);
    try {
      const response = await userService.updateUserRole(
        { role: value },
        user?.id
      );
      if (response) {
        const data = await getUsers();
        setUsersData(data);
        successToast(`${user.name}'s role updated to ${value}`);
        setRoleUpdated(!roleRef.current);
      }
    } catch (error) {
      errorToast(TOAST_MESSAGES.SOMETHING_WENT_WRONG);
    }
    setUserLoader(false);
  };

  const getUsers = async (): Promise<IUser[]> => {
    setUserLoader(true);
    const response = await userService.getUsers({
      currentPage,
      pageLimit,
      searchKeyword,
    });
    const { users, totalUsers } = response.data;

    setTotalUsers(totalUsers);

    const uniqueRoles = users.reduce((unique: any, item: any) => {
      if (item.role !== null) {
        const isUnique = !unique.some(
          (uniqueItem: any) => uniqueItem.label === item.role
        );
        if (isUnique) {
          unique.push({ value: item.role, label: item.role });
        }
      }
      return unique;
    }, []);

    if (!uniqueRoles.some((role: any) => role.label === 'NEWER')) {
      uniqueRoles.push({ value: 'NEWER', label: 'NEWER' });
    }

    if (!uniqueRoles.some((role: any) => role.label === 'ADMIN')) {
      uniqueRoles.push({ value: 'ADMIN', label: 'ADMIN' });
    }

    const data = users?.map((user: User) => {
      return {
        key: user?.id,
        name: user?.name,
        email: user?.email,
        action: (
          <Select
            defaultValue={user?.role}
            style={{ width: 120 }}
            onChange={(e) => handleRoleChange(e, user)}
            options={uniqueRoles}
          />
        ),
      };
    });
    setUserLoader(false);
    return data;
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any
  ) => {
    console.log(sorter);
    setSortedInfo(sorter);
  };

  const onShowSizeChange: PaginationProps['onShowSizeChange'] = (
    current,
    pageSize
  ) => {
    setPageLimit(pageSize);
  };

  let columns: ColumnsType<any> = [
    {
      title: <div className="title">Name</div>,
      dataIndex: 'name',
      key: 'name',
      width: '35%',
    },
    {
      title: <div className="title">Email Id</div>,
      dataIndex: 'email',
      key: 'email',
      width: '35%',
    },
    {
      title: <div className="action">Action</div>,
      dataIndex: 'action',
      key: 'action',
      width: '30%',
    },
  ];

  if (loader) {
    return <LoaderSpinner noText={true} />;
  }

  return (
    <div className="faq-page manage-faq-page">
      <div className="faq-title">
        <h2>
          {role
            ? role.charAt(0).toUpperCase() + role.slice(1) + 's'
            : 'Manage Users'}
        </h2>
        {!role && (
          <div className="search-input-wrapper">
            <Input
              type="text"
              placeholder="Search by Name, Email Id"
              className="search-input"
              onChange={(e) => handleSearch(e.target.value)}
              value={searchKeyword}
            />
            {searchKeyword ? (
              <span className="clear-search" onClick={() => handleSearch('')}>
                <CloseCircleFilled />
                <span className="clear-text">Clear search</span>
              </span>
            ) : null}
          </div>
        )}
      </div>
      <SuperAdminHome roleUpdated={roleUpdated} />
      {!role && (
        <>
          <Table
            columns={columns}
            dataSource={usersData}
            scroll={{ x: 600, y: 655 }}
            loading={{
              indicator: <LoaderSpinner noText={true} />,
              spinning: userLoader,
            }}
            className="table-paginated faq paginated-table-wrapper"
            pagination={false}
            onChange={handleTableChange}
          />
          <Pagination
            defaultCurrent={currentPage}
            defaultPageSize={pageLimit}
            total={totalUsers}
            onChange={(page) => setCurrentpage(page)}
            onShowSizeChange={onShowSizeChange}
            responsive={true}
            showSizeChanger={true}
            showTotal={(total) => `Total ${total} records`}
          />
        </>
      )}
    </div>
  );
};

ManageUsers.defaultProps = {};

export default ManageUsers;
