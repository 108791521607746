import {
  QuestionCircleOutlined,
  QuestionCircleFilled,
  GiftOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Layout, Tabs } from 'antd';
import './index.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  HomeIcon,
  MyReferral,
  ClaimBonus,
  ReferralPolicy,
  FaqIcon,
} from './svgIcons';
import { REFERRAL_POLICY_URL } from '../../Constants';
import MobileNavigation from './MobileNavigation';

const { Sider } = Layout;

const Navigation = (props: {
  isSideBarCollapsed: any;
  setSideBarCollapsed: any;
  userData: any;
}) => {
  const { isSideBarCollapsed, setSideBarCollapsed, userData } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const navIconColor = (pathname: string) => {
    if (pathname === location.pathname) {
      return '#DE1186';
    } else {
      return '#2e1c41';
    }
  };

  let tabs = [
    {
      value: 'Home',
      path:
        userData?.role === 'ADMIN' || userData?.role === 'SUPERADMIN'
          ? '/admin/home'
          : '/',
      icon: (
        <span className="anticon anticon-user">
          <HomeIcon
            color={navIconColor(
              userData?.role === 'ADMIN' || userData?.role === 'SUPERADMIN'
                ? '/admin/home'
                : '/'
            )}
          />
        </span>
      ),
      permission: ['NEWER', 'ADMIN', 'SUPERADMIN'],
    },
    {
      value: 'Manage FAQ Categories',
      path: '/admin/manage-faq-categories',
      icon: <QuestionCircleFilled style={{ fontSize: '20px' }} />,
      permission: ['SUPERADMIN', 'ADMIN'],
    },
    {
      value: 'Manage FAQ',
      path: '/admin/manage-faq',
      icon: <QuestionCircleOutlined style={{ fontSize: '20px' }} />,
      permission: ['SUPERADMIN', 'ADMIN'],
    },
    {
      value: 'Manage Referral Bonus',
      path: '/admin/manage-referral-bonus',
      icon: <GiftOutlined style={{ fontSize: '20px' }} />,
      permission: ['SUPERADMIN', 'ADMIN'],
    },

    {
      value: 'Manage Users',
      path: '/super-admin/manage-users',
      icon: <UserOutlined style={{ fontSize: '20px' }} />,
      permission: ['SUPERADMIN'],
    },

    {
      value: 'My Referral',
      path: '/my-referral',
      icon: (
        <span className="anticon anticon-user">
          <MyReferral color={navIconColor('/my-referral')} />
        </span>
      ),
      permission: ['NEWER'],
    },
    // {
    //   value: <span className='external-link'>Claim Referral Bonus<span><img src="/images/external-link.png" alt="external"/></span></span>,
    //   path: '/claim-referral',
    //   icon: <span className='anticon anticon-user'><ClaimBonus color={navIconColor("/claim-referral")}/></span>,
    //   permission: 'NEWER',
    // },
    {
      value: (
        <span className="external-link">
          Referral Policy
          <span>
            <img src="/images/external-link.png" alt="external" />
          </span>
        </span>
      ),
      path: '/referral-policy',
      icon: (
        <span className="anticon anticon-user">
          <ReferralPolicy color={navIconColor('/referral-policy')} />
        </span>
      ),
      permission: ['NEWER'],
    },
    {
      value: 'FAQs',
      path: '/faqs',
      icon: (
        <span className="anticon anticon-user">
          <FaqIcon color={navIconColor('/faqs')} />
        </span>
      ),
      permission: ['NEWER'],
    },
  ];

  const onTabChange = (e: any) => {
    if (window.innerWidth <= 991) {
      setSideBarCollapsed(!isSideBarCollapsed);
    }
    if (e === '/referral-policy') {
      window.open(REFERRAL_POLICY_URL, '_blank');
      return false;
    }
    navigate(e);
  };

  return (
    <Sider
      className={'navigation' + (isSideBarCollapsed ? ' show' : '')}
      width={'100%'}
    >
      {window.innerWidth > 991 ? (
        <Tabs
          defaultActiveKey={location.pathname}
          activeKey={location.pathname}
          tabPosition="left"
          style={{ height: '100%' }}
          onChange={onTabChange}
        >
          {tabs.map((tab, value) => {
            return (
              tab.permission &&
              Array.isArray(tab.permission) &&
              tab.permission.includes(userData?.role) && (
                <Tabs.TabPane
                  tab={
                    <span>
                      {tab.icon}
                      {tab.value}
                    </span>
                  }
                  key={tab.path}
                />
              )
            );
          })}
        </Tabs>
      ) : (
        <MobileNavigation
          tabs={tabs}
          onTabChange={onTabChange}
          userData={userData}
        />
      )}
      <div className="nav-footer">TO THE NEW © 2023</div>
    </Sider>
  );
};
export default Navigation;
