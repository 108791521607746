import { AxiosService } from '../apiService';
import GlobalConfig from '../globalConfig';
import { getApi, putApi } from '../repository';

class UserService {
    getUsers({ currentPage, pageLimit, searchKeyword, role }: { currentPage?: number; pageLimit?: number; searchKeyword?: string, role?: string }) {
        const requestObj: any = { ...getApi };
        requestObj.url = `${GlobalConfig.API_ROOT}/users?${(currentPage !== 0 && currentPage !== undefined) ? 'page=' + currentPage : ''}${(pageLimit !== 0 && pageLimit !== undefined) ? '&limit=' + pageLimit : ''
            }${searchKeyword ? '&searchKeyword=' + searchKeyword : ''}${(role !== null && role !== undefined) ? '&role=' + role : ''}`;
        const api = new (AxiosService as any)(requestObj);
        return api.doAjax();
    }

    updateUserRole(params: object, id: number) {
        const requestObj: any = { ...putApi };
        requestObj.params = params;
        requestObj.url = `${GlobalConfig.API_ROOT}/users/update-role/${id}`;
        const api = new (AxiosService as any)(requestObj);
        return api.doAjax();
    }

}
export const userService = new UserService();
