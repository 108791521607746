import React from 'react';
import { Spin } from 'antd';
import "./index.scss";

interface IMyProps {
  noText?: boolean,
}

const LoaderSpinner: React.FC<IMyProps> = (props: IMyProps) => (
  <Spin tip={props.noText ? "" : "Loading"} size="large" className="loader-spinner"></Spin>
);

export default LoaderSpinner;