import { AxiosService } from '../apiService';
import GlobalConfig from '../globalConfig';
import { deleteApi, getApi, postApi, putApi } from '../repository';

interface GetReferralParams {
  status?: string[];
  type?: string;
  jobRoleId?: number;
  searchKeyword?: string;
  currentPage?: number;
  pageLimit?: number;
  startDate?: string;
  endDate?: string;
  jobId?: string | null;
  stage?: string;
  competencyIds?: number[];
  isRepushed?: string;
  isProcessed?: string;
  recruiter?: string;
}
class ReferralServices {
  uploadResume(params: FormData) {
    const requestObj: any = {
      method: 'POST',
      headers: GlobalConfig.getHeaders(['MULTIPART_FORM_DATA']),
    };
    requestObj.params = params;
    requestObj.url = `${GlobalConfig.API_ROOT}/referrals/upload-resume`;
    const api = new (AxiosService as any)(requestObj);
    return api.doAjax();
  }
  createReferral(params: object) {
    const requestObj: any = { ...postApi };
    requestObj.params = params;
    requestObj.url = `${GlobalConfig.API_ROOT}/referrals`;
    const api = new (AxiosService as any)(requestObj);
    return api.doAjax();
  }
  getReferrals(params: GetReferralParams) {
    const requestObj: any = { ...getApi };
    let competencyIdsStr;
    if (params.competencyIds?.length) {
      competencyIdsStr = params.competencyIds.join(',');
    }

    // requestObj.url = `${GlobalConfig.API_ROOT}/referrals`
    // requestObj.params = { ...params};
    // for(let key in requestObj.params){
    //   if(!requestObj.params[key]) {
    //     delete requestObj.params[key]
    //   }
    // }
    if (params.status) {
      requestObj.url = `${GlobalConfig.API_ROOT}/referrals?${params.type}=${
        params.status
      }${params.searchKeyword ? '&searchKeyword=' + params.searchKeyword : ''}${
        params.startDate ? '&startDate=' + params.startDate : ''
      }${params.endDate ? '&endDate=' + params.endDate : ''}`;

      if (params.jobRoleId) {
        requestObj.url = `${GlobalConfig.API_ROOT}/referrals?${params.type}=${
          params.status
        }&jobRoleId=${params.jobRoleId}${
          params.searchKeyword ? '&searchKeyword=' + params.searchKeyword : ''
        }${params.startDate ? '&startDate=' + params.startDate : ''}${
          params.endDate ? '&endDate=' + params.endDate : ''
        }`;
      }
      if (params.isRepushed) {
        requestObj.url = `${GlobalConfig.API_ROOT}/referrals?${params.type}=${
          params.status
        }${
          params.jobRoleId ? '&jobRoleId=' + params.jobRoleId : ''
        }&isRepushed=${params.isRepushed}${
          params.searchKeyword ? '&searchKeyword=' + params.searchKeyword : ''
        }${params.startDate ? '&startDate=' + params.startDate : ''}${
          params.endDate ? '&endDate=' + params.endDate : ''
        }`;
      }
      if (params.isProcessed) {
        requestObj.url = `${GlobalConfig.API_ROOT}/referrals?${params.type}=${
          params.status
        }${params.jobRoleId ? '&jobRoleId=' + params.jobRoleId : ''}${
          params.isRepushed ? '&isRepushed=' + params.isRepushed : ''
        }&isProcessed=${params.isProcessed}${
          params.searchKeyword ? '&searchKeyword=' + params.searchKeyword : ''
        }${params.startDate ? '&startDate=' + params.startDate : ''}${
          params.endDate ? '&endDate=' + params.endDate : ''
        }`;
      }
      if (params.competencyIds?.length) {
        requestObj.url = `${GlobalConfig.API_ROOT}/referrals?${params.type}=${
          params.status
        }${params.jobRoleId ? '&jobRoleId=' + params.jobRoleId : ''}${
          params.isRepushed ? '&isRepushed=' + params.isRepushed : ''
        }${
          params.isProcessed ? '&isProcessed=' + params.isProcessed : ''
        }&competencyIds=${competencyIdsStr}${
          params.searchKeyword ? '&searchKeyword=' + params.searchKeyword : ''
        }${params.startDate ? '&startDate=' + params.startDate : ''}${
          params.endDate ? '&endDate=' + params.endDate : ''
        }`;
      }
      if (params.recruiter) {
        requestObj.url = `${GlobalConfig.API_ROOT}/referrals?${params.type}=${
          params.status
        }
        ${params.jobRoleId ? '&jobRoleId=' + params.jobRoleId : ''}${
          params.isRepushed ? '&isRepushed=' + params.isRepushed : ''
        }${params.isProcessed ? '&isProcessed=' + params.isProcessed : ''}
        ${
          params.competencyIds?.length
            ? '&competencyIds=' + competencyIdsStr
            : []
        }&recruiter=${params.recruiter}${
          params.searchKeyword ? '&searchKeyword=' + params.searchKeyword : ''
        }${params.startDate ? '&startDate=' + params.startDate : ''}${
          params.endDate ? '&endDate=' + params.endDate : ''
        }`;
      }
    } else {
      requestObj.url = `${GlobalConfig.API_ROOT}/referrals?${
        params.searchKeyword ? '&searchKeyword=' + params.searchKeyword : ''
      }${params.startDate ? '&startDate=' + params.startDate : ''}${
        params.endDate ? '&endDate=' + params.endDate : ''
      }`;
      if (params.jobRoleId) {
        requestObj.url = `${GlobalConfig.API_ROOT}/referrals?jobRoleId=${
          params.jobRoleId
        }${
          params.searchKeyword ? '&searchKeyword=' + params.searchKeyword : ''
        }${params.startDate ? '&startDate=' + params.startDate : ''}${
          params.endDate ? '&endDate=' + params.endDate : ''
        }`;
      }
      if (params.isRepushed) {
        requestObj.url = `${GlobalConfig.API_ROOT}/referrals?isRepushed=${
          params.isRepushed
        }${params.jobRoleId ? '&jobRoleId=' + params.jobRoleId : ''}${
          params.searchKeyword ? '&searchKeyword=' + params.searchKeyword : ''
        }${params.startDate ? '&startDate=' + params.startDate : ''}${
          params.endDate ? '&endDate=' + params.endDate : ''
        }`;
      }
      if (params.isProcessed) {
        requestObj.url = `${GlobalConfig.API_ROOT}/referrals?isProcessed=${
          params.isProcessed
        }${params.isRepushed ? '&isRepushed=' + params.isRepushed : ''}${
          params.jobRoleId ? '&jobRoleId=' + params.jobRoleId : ''
        }${
          params.searchKeyword ? '&searchKeyword=' + params.searchKeyword : ''
        }${params.startDate ? '&startDate=' + params.startDate : ''}${
          params.endDate ? '&endDate=' + params.endDate : ''
        }`;
      }
      if (params.competencyIds?.length) {
        requestObj.url = `${
          GlobalConfig.API_ROOT
        }/referrals?competencyIds=${competencyIdsStr}${
          params.isRepushed ? '&isRepushed=' + params.isRepushed : ''
        }${params.isProcessed ? '&isProcessed=' + params.isProcessed : ''}${
          params.jobRoleId ? '&jobRoleId=' + params.jobRoleId : ''
        }${
          params.searchKeyword ? '&searchKeyword=' + params.searchKeyword : ''
        }${params.startDate ? '&startDate=' + params.startDate : ''}${
          params.endDate ? '&endDate=' + params.endDate : ''
        }`;
      }

      if (params.recruiter) {
        requestObj.url = `${GlobalConfig.API_ROOT}/referrals?recruiter=${
          params.recruiter
        }${params.isRepushed ? '&isRepushed=' + params.isRepushed : ''}${
          params.isProcessed ? '&isProcessed=' + params.isProcessed : ''
        }${params.jobRoleId ? '&jobRoleId=' + params.jobRoleId : ''}${
          params.searchKeyword ? '&searchKeyword=' + params.searchKeyword : ''
        }${
          params.competencyIds?.length
            ? '&competencyIds=' + competencyIdsStr
            : []
        }${
          params.searchKeyword ? '&searchKeyword=' + params.searchKeyword : ''
        }${params.startDate ? '&startDate=' + params.startDate : ''}${
          params.endDate ? '&endDate=' + params.endDate : ''
        }`;
      }
    }

    requestObj.url = `${requestObj.url}&page=${params.currentPage}&limit=${params.pageLimit}&jobId=${params.jobId}&stage=${params.stage}`;
    const api = new (AxiosService as any)(requestObj);
    return api.doAjax();
  }

  getReferralAnalytics(
    startDate?: string,
    endDate?: string,
    jobId?: string | null
  ) {
    const requestObj: any = { ...getApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/referrals/referral-analytics?${
      startDate ? 'startDate=' + startDate : ''
    }${endDate ? '&endDate=' + endDate : ''}${jobId ? '&jobId=' + jobId : ''}`;
    const api = new (AxiosService as any)(requestObj);
    return api.doAjax();
  }
  getResumeUrl(resumeName: string) {
    const requestObj: any = { ...getApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/referrals/resume-url?resume=${resumeName}`;
    const api = new (AxiosService as any)(requestObj);
    return api.doAjax();
  }
  pushReferralsToZoho(params: any) {
    const requestObj: any = { ...postApi };
    requestObj.params = params;
    requestObj.url = `${GlobalConfig.API_ROOT}/referrals/push-to-zoho`;
    const api = new (AxiosService as any)(requestObj);
    return api.doAjax();
  }
  repushCandidate(params: object) {
    const requestObj: any = { ...postApi };
    requestObj.params = params;
    requestObj.url = `${GlobalConfig.API_ROOT}/referrals/repush-candidate`;
    const api = new (AxiosService as any)(requestObj);
    return api.doAjax();
  }

  getAllReferrals() {
    const requestObj: any = { ...getApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/referrals/all`;
    const api = new (AxiosService as any)(requestObj);
    return api.doAjax();
  }

  createReferralBonus(params: any) {
    const requestObj: any = { ...postApi };
    requestObj.params = params;
    requestObj.url = `${GlobalConfig.API_ROOT}/referrals/referral-bonus`;
    const api = new (AxiosService as any)(requestObj);
    return api.doAjax();
  }

  getReferralBonus(lob: string) {
    const requestObj: any = { ...getApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/referrals/referral-bonus?lob=${lob}`;
    const api = new (AxiosService as any)(requestObj);
    return api.doAjax();
  }

  deleteReferralBonus(id: number) {
    const requestObj: any = { ...deleteApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/referrals/referral-bonus?id=${id}`;
    const api = new (AxiosService as any)(requestObj);
    return api.doAjax();
  }
  updateReferralBonus(id: number, params: any) {
    console.log(id, params);
    const requestObj: any = { ...putApi };
    requestObj.params = params;
    requestObj.url = `${GlobalConfig.API_ROOT}/referrals/referral-bonus?id=${id}`;
    const api = new (AxiosService as any)(requestObj);
    return api.doAjax();
  }

  getStatusMapping(stage = '') {
    const requestObj: any = { ...getApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/referrals/status-mapping?stage=${stage}`;
    const api = new (AxiosService as any)(requestObj);
    return api.doAjax();
  }

  unassociateToJob(id: number, isLock = false) {
    console.log(id, 'frontend jobid');
    const requestObj: any = { ...putApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/referrals/unassociate-to-job?id=${id}&isLock=${isLock}`;
    const api = new (AxiosService as any)(requestObj);
    return api.doAjax();
  }

  getDuplicateReferrals(referralId: string) {
    const requestObj: any = { ...getApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/referrals/duplicates?id=${referralId}`;
    const api = new (AxiosService as any)(requestObj);
    return api.doAjax();
  }
}

export const referralServices = new ReferralServices();
