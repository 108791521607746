import { API_ROOT } from "./Constants/endPoints";
const APP_VERSION = 1.0;
const Settings = {
  APP_VERSION,
  API_ROOT: process.env.NODE_ENV !== 'production' ? API_ROOT : 'https://referralportal.qa2.tothenew.net/api',
  THEME_CONFIG: {
    DOMAIN1: process.env.REACT_APP_DOMAIN1,
    DOMAIN2: process.env.REACT_APP_DOMAIN2,
    DOMAIN3: process.env.REACT_APP_DOMAIN3,
  },
  API_ERROR: 0,
  API_SUCCESS: 1,
  MESSAGES_TYPES: {
    TRUE: true,
    FALSE: false,
    SUCCESS: 'SUCCESS',
    PENDING: 'PENDING',
    IN_PROGRESS: 'INPROGRESS',
    FAIL: 'FAIL',
  },
  serverError: 'Something went wrong. Please try again later',
  noDataFound: 'No Data Found',
  DEFAULT_DATE_FORMAT: 'DD MMM YYYY',
  getHeaders(headers:any) {
    const HEADERS:any = {
      JSON: {
        'Content-Type': 'application/json',
      },
      URL_ENCODED: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      MULTIPART_FORM_DATA: {
        'Content-Type': 'multipart/form-data',
      },
      TEXT_HTML: {
        'Content-Type': 'text/html',
      },
    };
    function getHeaders() {
      let headerObj:[] = [];
      Object.keys(headers).forEach((key) => {
        const header = HEADERS[headers[key]];
        headerObj = Object.assign(headerObj, header);
      });
      return headerObj;
    }
    return getHeaders();
  }
};

export default Settings;
