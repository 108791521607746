import { useState, useEffect } from 'react';
import { MenuOutlined, CloseOutlined, LogoutOutlined } from '@ant-design/icons';
import { Avatar, Layout, Dropdown } from 'antd';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import { notificationServices } from '../../Services/notificationService';
import { errorToast } from '../Common/ToastService';
import { loginServices } from '../../Services/loginServices';
const { Header } = Layout;
const AppHeader = (props: {
  setSideBarCollapsed: any;
  isSideBarCollapsed: any;
}) => {
  const { setSideBarCollapsed, isSideBarCollapsed } = props;

  const [openApps, setOpenApps] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const navigate = useNavigate();
  const localUsersData = localStorage.getItem('referral-user-data');
  const userData = JSON.parse(localUsersData as string);
  const [notifications, setNotifications] = useState<any[]>([]);

  const handleOpenAppsChange = (flag: boolean) => {
    setOpenApps(flag);
  };

  const handleOpenProfileChange = (flag: boolean) => {
    setOpenProfile(flag);
  };

  // useEffect(() => {
  //   (async () => {
  //     const notificationData = await getNotifications();
  //     setNotifications(notificationData);
  //   })();
  // }, []);

  const getNotifications = async () => {
    try {
      const notificationResponse =
        await notificationServices.getNotifications();
      return notificationResponse.data;
    } catch (error: any) {
      errorToast(error.response.data.error);
    }
  };

  const links = [
    {
      linkPath: 'http://learning.tothenew.com/',
      imagePath: '/images/nw-icons/learnning.png',
      class: 'learning-icon',
      title: 'Learning',
    },
    {
      linkPath: 'https://growth.tothenew.com/',
      imagePath: '/images/nw-icons/growth.png',
      class: 'growth-icon',
      title: 'Growth',
    },
    {
      linkPath: 'https://investment-declaration.tothenew.com/',
      imagePath: '/images/nw-icons/investment.png',
      class: 'investment-icon',
      title: 'Investment Declaration',
    },
    {
      linkPath: 'https://asset-desk.tothenew.com/',
      imagePath: '/images/nw-icons/asset_desk.png',
      class: 'asset-icon',
      title: 'Asset Desk',
    },
    {
      linkPath: 'https://reimbursement.tothenew.com/#/',
      imagePath: '/images/nw-icons/reimbursement.png',
      class: 'claims-icon',
      title: 'Reimbursement Claims',
    },
    {
      linkPath: 'https://timesheet.tothenew.com/',
      imagePath: '/images/nw-icons/timesheet.png',
      class: 'timesheet-icon',
      title: 'Timesheet',
    },
  ];

  const logout = () => {
    loginServices.logout();
    navigate('/login');
  };

  const addHeightToBody = () => {
    if (isSideBarCollapsed) {
      document
        .querySelectorAll<HTMLElement>('body,  #root, .App, .App > div')
        .forEach((node) => (node.style.height = ''));
    } else {
      document
        .querySelectorAll<HTMLElement>('body,  #root, .App, .App > div')
        .forEach((node) => (node.style.height = '100%'));
    }
  };

  return (
    <Header className="header">
      <div
        className="hamburger-menu"
        onClick={() => {
          setSideBarCollapsed(!isSideBarCollapsed);
          addHeightToBody();
        }}
      >
        <MenuOutlined
          className={`${
            isSideBarCollapsed ? 'hide-menu-icon' : 'show-menu-icon'
          }`}
        />
        <CloseOutlined
          className={`${
            isSideBarCollapsed ? 'show-menu-icon' : 'hide-menu-icon'
          }`}
        />
      </div>
      <div className="left-section">
        <img src="/images/Logo.png" alt="To The New" />
      </div>
      <div className="right-section">
        {userData && userData.role !== 'ADMIN' ? (
          <>
            {/* <Dropdown
            onOpenChange={setOpenNotification}
            open={openNotification}
            trigger={window.innerWidth <= 991 ? ['click'] : ['hover']}
            align={{ offset: [0, -5] }}
            dropdownRender={() => (
              <ul className="notifications-dropdown">
                {notifications?.map((notification) => (
                  <li className="notification">{truncate(notification.subject, 70)}</li>
                ))}
              </ul>
            )}
          >
            <span className='header-icon bell-icon'>
              <img src="/images/notification.svg" alt="bell"/>
              {
                notifications && notifications.length > 0 ? 
                <span className='notification-count'>{notifications.length > 9 ? '9+' : notifications.length}</span>
                : null
              }
            </span>
          </Dropdown> */}

            <Dropdown
              onOpenChange={handleOpenAppsChange}
              align={{ offset: [-50, 5] }}
              open={openApps}
              trigger={window.innerWidth <= 991 ? ['click'] : ['hover']}
              dropdownRender={() => (
                <ul className="applications-dropdown">
                  {links.map((item, idx) => (
                    <li>
                      <a className="link" target="_blank" href={item.linkPath}>
                        <div className={`${item.class} app-icon`}></div>
                        <p>{item.title}</p>
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            >
              <span className="header-icon applications">
                <img src="/images/app.svg" alt="apps" />
              </span>
            </Dropdown>
          </>
        ) : (
          ''
        )}

        <Dropdown
          onOpenChange={handleOpenProfileChange}
          open={openProfile}
          trigger={window.innerWidth <= 991 ? ['click'] : ['hover']}
          align={{ offset: [-66, 0] }}
          dropdownRender={() => (
            <ul className="user-profile">
              <li onClick={logout}>
                Logout <LogoutOutlined />
              </li>
            </ul>
          )}
        >
          <Avatar
            className="header-icon profile"
            icon={<img src={userData?.profilePicUrl} alt="avatar" />}
          />
        </Dropdown>
      </div>
    </Header>
  );
};
export default AppHeader;
