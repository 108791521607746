import GlobalConfig from './globalConfig';

export const postApi = {
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
};

export const getApi = {
  method: 'GET',
  headers: GlobalConfig.getHeaders(['JSON']),
};

export const deleteApi = {
  method: 'DELETE',
  headers: GlobalConfig.getHeaders(['JSON']),
};

export const putApi = {
  method: 'PUT',
  headers: GlobalConfig.getHeaders(['JSON']),
};
