import { message } from 'antd';

import { TOAST_MESSAGES } from '../../../Constants';

const TOAST_TIME = 3; // 3 seconds

export const successToast = (displayMessage = 'Success') => {
  message.success(displayMessage, TOAST_TIME);
};

export const errorToast = (
  displayMessage = TOAST_MESSAGES.SOMETHING_WENT_WRONG
) => {
  message.error(displayMessage, TOAST_TIME);
};

export const warningToast = (
  displayMessage = TOAST_MESSAGES.SOMETHING_WENT_WRONG
) => {
  message.warning(displayMessage, TOAST_TIME);
};
