export interface AppContract {
  userName?: string;
  isAuthenticated: boolean;
  doLogin: () => void;
}

export interface JobContract {
  post?: string;
  experience?: string;
  location?: string;
}

export interface DateRange {
  startDate?: string;
  endDate?: string;
}

export const genericVerbiage = {
  noTitle: 'No Title',
  pageTitle: 'Dummy',
};
export const errorPageVerbiage = {
  title: 'Sorry!',
  description: 'This feature not available in this prototype.',
  linkLabel: 'go back',
};
