import { AxiosService } from '../apiService';
import GlobalConfig from '../globalConfig';
import { deleteApi, getApi, postApi, putApi } from '../repository';

class FaqService {
  getFaqs({ currentPage, pageLimit, searchKeyword }: { currentPage?: number; pageLimit?: number; searchKeyword?: string }) {
    const requestObj: any = { ...getApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/faqs?${currentPage !== 0 ? 'page=' + currentPage : ''}${
      pageLimit !== 0 ? '&limit=' + pageLimit : ''
    }${searchKeyword ? '&searchKeyword=' + searchKeyword : ''}`;
    const api = new (AxiosService as any)(requestObj);
    return api.doAjax();
  }

  createFaq(params: object) {
    const requestObj: any = { ...postApi };
    requestObj.params = params;
    requestObj.url = `${GlobalConfig.API_ROOT}/faqs`;
    const api = new (AxiosService as any)(requestObj);
    return api.doAjax();
  }

  updateFaq(params: object, id: number) {
    const requestObj: any = { ...putApi };
    requestObj.params = params;
    requestObj.url = `${GlobalConfig.API_ROOT}/faqs/${id}`;
    const api = new (AxiosService as any)(requestObj);
    return api.doAjax();
  }

  deleteFaq(id: number) {
    const requestObj: any = { ...deleteApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/faqs/${id}`;
    const api = new (AxiosService as any)(requestObj);
    return api.doAjax();
  }
}
export const faqService = new FaqService();
